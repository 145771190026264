<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Tedarikçi</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Tedarikçi Ekle</span>
      </h3>
      <v-col class="text-right" v-if="id">
        <rs-action
          :to="{
            name: 'expenses.invoice.create',
            query: { provider_id: id },
          }"
          v-if="can('edit-invoice')"
        >
          Fatura Ekle
        </rs-action>

        <rs-action
          v-if="originalData && originalData.parent_id"
          @click="handleDisrespectParentClick()"
          @confirmed="handleDisrespectParentClick()"
          title="Şirket tedarikçisiyle olan bağı koparır."
          tooltip="Şirket tedarikçisiyle olan bağı koparır."
          id="action1"
          confirmed
        >
          Şirket Tedarikçisinden Ayır
        </rs-action>

        <rs-action
          v-if="originalData && originalData.parent_id === null"
          title="Bir şirket tedarikçisiyle ilişkilendirir."
          tooltip="Bir şirket tedarikçisiyle ilişkilendirir."
          id="respectParentButton"
          v-on="on"
          v-attrs="attrs"
        >
          Şirket Tedarikçisiyle İlişkilendir
        </rs-action>

        <v-dialog
          v-if="
            originalData && originalData.parent_id === null && clusterHasCompany
          "
          v-model="dialog"
          persistent
          max-width="600px"
          activator="#respectParentButton"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Şirket Tedarikçisi Oluştur</span>
            </v-card-title>
            <v-form ref="closeForm" @submit.prevent="handleRespectParentClick">
              <v-card-text>
                <v-container>
                  <p class="mb-0">
                    <strong>Dikkat:</strong>
                    Şirket tedarikçisinin isim, unvan gibi bilgileri TYA
                    tedarikçisinin üzerine yazılacak.
                  </p>
                  <rs-select-company-provider
                    required
                    v-model="selectedCompanyProviderId"
                    :rules="[rules.required]"
                    :company-id="cluster.active_company.id"
                  />
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  İptal
                </v-btn>
                <v-btn type="submit" color="blue darken-1" text>
                  Kaydet ve Kapat
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <rs-action
          v-if="createParentEnabled"
          @click="handleCreateParentClick()"
          @confirmed="handleCreateParentClick()"
          title="Bu tedarikçinin bilgileriyle yeni bir şirket tedarikçisi oluşturur."
          id="action2"
          tooltip="Bu tedarikçinin bilgileriyle yeni bir şirket tedarikçisi oluşturur."
          confirmed
          confirm-text="Mevcut tedarikçi bilgileriyle yeni bir şirket tedarikçisi oluşturulacak. Emin misiniz?"
        >
          Şirket Tedarikçisi Oluştur
        </rs-action>

        <rs-action
          :to="{
            name: 'definitions.cluster-providers.edit',
            params: { id: id },
          }"
          v-if="id && can('edit-cluster-provider') && disabled"
          title="Düzenle"
          icon
          tooltip="Düzenle"
        >
          <v-icon>mdi-pencil</v-icon>
        </rs-action>

        <rs-action
          @click="showActionLogs()"
          v-if="id && can('edit-cluster-provider')"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-banner
        elevation="1"
        v-if="originalData && originalData.parent_id"
        class="mb-3 bg-light-info"
      >
        Bu tedarikçi bir yönetim şirketi tedarikçisidir. Bu sebeple bu formda
        düzenleme yapamazsınız. Şirket tedarikçisini düzenlemek için
        <router-link
          :to="{
            name: 'my-company.providers.edit',
            params: { id: originalData.parent_id },
          }"
          >tıklayın</router-link
        >.
      </v-banner>

      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Bilgiler</v-tab>
        <v-tab
          to="#payment-list"
          replace
          :disabled="!id"
          v-if="can('see-invoice')"
        >
          Aktif Ödeme Listesi
        </v-tab>
        <v-tab
          to="#transactions"
          replace
          :disabled="!id"
          v-if="can('see-balance-activity')"
        >
          Hesap Hareketleri
        </v-tab>
        <v-tab
          to="#auto-assessment"
          replace
          :disabled="!id || !originalData.auto_assessment"
        >
          Otomatik Gider Tahakkuk
        </v-tab>
        <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
          {{ $t("headers.notes_and_reminders") }}
        </v-tab>
        <v-tab
          to="#documents"
          replace
          :disabled="!id"
          v-if="can('see-document')"
        >
          {{ $t("headers.documents") }}
        </v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form @submit.prevent="handleFormSubmit" ref="form">
            <v-row dense>
              <v-col cols="12" sm="6">
                <rs-select
                  :items="providerTypeList"
                  :rules="[rules.required]"
                  :label="$t('labels.provider_type')"
                  v-model="formData.provider_type_id"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.provider_name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 120)]"
                  v-model="formData.name"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>

              <v-col sm="12">
                <rs-text-field
                  :label="$t('labels.provider_title')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.title, 200),
                  ]"
                  v-model="formData.title"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select
                  :items="providerbankWorkflowTypeList"
                  :rules="[rules.required]"
                  :item-text="(item) => $t(item.translation_key)"
                  :label="$t('labels.bank_workflow_collecting_type')"
                  v-model="formData.bank_workflow_type_id"
                  :readonly="disabled"
                  :filled="disabled"
                  required
                />
              </v-col>

              <!-- <v-col cols="12" sm="6">
                <rs-select
                  :items="bankIntegrationTypeList"
                  :label="$t('labels.bank_integration_type')"
                  v-model="formData.bank_integration_type_id"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col> -->

              <v-col cols="12" sm="6">
                <rs-select
                  :items="fullExpenseTypeList"
                  :rules="
                    formData.bank_integration_type_id ? [rules.required] : []
                  "
                  :label="$t('labels.income_expense_type')"
                  v-model="formData.expense_type_id"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                  :required="formData.bank_integration_type_id"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select
                  :label="$t('labels.expense_kind')"
                  :rules="[]"
                  :items="childExpenseTypeList"
                  v-model="formData.child_expense_type_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :disabled="!formData.expense_type_id"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.tax_office')"
                  :rules="[rules.maxLength(formData.tax_office, 20)]"
                  v-model="formData.tax_office"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.tax_number')"
                  :rules="[rules.maxLength(formData.tax_number, 20)]"
                  v-model="formData.tax_number"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-switch
                  v-model="formData.is_active"
                  :label="formData.is_active ? $t('active') : $t('passive')"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="6">
                <v-switch
                  v-model="formData.auto_assessment"
                  :label="$t('labels.auto_assessment')"
                  hide-details="auto"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                ></v-switch>
              </v-col>
            </v-row>

            <v-divider class="my-10" />

            <v-row v-if="id">
              <v-col cols="12">
                <h3>Durum</h3>
              </v-col>
              <v-col sm="4">
                <rs-money-field
                  :label="$t('labels.debt')"
                  :value="status.debt"
                  :disabled="true"
                />
              </v-col>

              <v-col sm="4">
                <rs-money-field
                  :label="$t('labels.due')"
                  :value="status.due"
                  :disabled="true"
                />
              </v-col>

              <v-col sm="4">
                <rs-money-field
                  :label="$t('labels.balance')"
                  :value="status.balance"
                  :disabled="true"
                />
              </v-col>
            </v-row>

            <v-divider class="my-10" />

            <v-row dense>
              <v-col cols="12" class="pb-0">
                <h3 class="mb-0">Bankalar</h3>
              </v-col>
              <v-col cols="12" sm="6">
                <rs-select-bank
                  v-model="formData.bank_1_id"
                  :label="$t('labels.bank_1')"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.bank_1_iban')"
                  :rules="[rules.iban]"
                  v-model="formData.bank_1_iban"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                  v-mask="'TR ## #### #### #### #### #### ##'"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-select-bank
                  v-model="formData.bank_2_id"
                  :label="$t('labels.bank_2')"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.bank_2_iban')"
                  :rules="[rules.iban]"
                  v-model="formData.bank_2_iban"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                  v-mask="'TR ## #### #### #### #### #### ##'"
                />
              </v-col>

              <v-col cols="12">
                <rs-bank-keywords
                  v-model="formData.bank_keywords"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>
            </v-row>

            <v-divider class="my-10" />

            <v-row dense>
              <v-col cols="12" class="pb-0">
                <h3 class="mb-0">Adres Bilgileri</h3>
              </v-col>

              <v-col sm="6" md="4">
                <rs-tel-field
                  :label="$t('labels.company_phone')"
                  v-model="formData.phone"
                  :disabled="disabled || hasParent"
                  :rules="[
                    formData.phone != ''
                      ? rules.minLength(formData.phone, 7)
                      : true,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>

              <v-col sm="6" md="4">
                <rs-text-field
                  type="email"
                  :label="$t('labels.company_email')"
                  :rules="[rules.email, rules.maxLength(formData.email, 120)]"
                  v-model="formData.email"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                />
              </v-col>

              <v-col sm="6" md="4">
                <rs-text-field
                  type="url"
                  :label="$t('labels.website')"
                  :rules="[rules.maxLength(formData.website, 500)]"
                  v-model="formData.website"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select-location
                  :label="$t('labels.city')"
                  v-model="formData.city_id"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                  :location-type-id="2"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select-location
                  :label="$t('labels.district')"
                  v-model="formData.district_id"
                  :readonly="disabled || hasParent"
                  :filled="disabled || hasParent"
                  :location-type-id="3"
                  :parent-id="formData.city_id"
                  :disabled="!formData.city_id"
                />
              </v-col>

              <v-col>
                <v-textarea
                  autocomplete="street-address"
                  :label="$t('labels.company_address')"
                  :rules="[rules.maxLength(formData.address, 500)]"
                  v-model="formData.address"
                  rows="3"
                  no-resize
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
            </v-row>

            <v-divider class="my-10" />

            <v-row dense>
              <v-col cols="12" class="pb-0">
                <h3>İlgili Bilgileri</h3>
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.concern_name')"
                  :rules="[rules.maxLength(formData.concern_name, 100)]"
                  v-model="formData.concern_name"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.concern_position')"
                  :rules="[rules.maxLength(formData.concern_position, 100)]"
                  v-model="formData.concern_position"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  type="email"
                  :label="$t('labels.concern_email')"
                  :rules="[rules.maxLength(formData.concern_email, 200)]"
                  v-model="formData.concern_email"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-tel-field
                  :label="$t('labels.concern_phone')"
                  v-model="formData.concern_phone"
                  :disabled="disabled"
                  :rules="[
                    formData.concern_phone != ''
                      ? rules.minLength(formData.concern_phone, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>
            </v-row>

            <rs-form-buttons
              :is-loading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="payment-list" class="pa-4">
          <PaymentList :provider-id="id" />
        </v-tab-item>
        <v-tab-item value="transactions" class="pa-4">
          <TransactionList :provider-id="id" :scopes="['provider']" />
        </v-tab-item>
        <v-tab-item value="auto-assessment" class="pa-4">
          <AutoAssessmentList :provider-id="id" />
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4">
          <!-- TODO: the notes should be separate based on "mode" -->
          <NoteList
            :allow-add="true"
            :extra-params="{
              provider_id: id,
              cluster_company_id: 1,
            }"
            :form-subtitle="originalData.title || originalData.name"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4">
          <!-- TODO: the notes should be separate based on "mode" -->
          <DocumentList
            :allow-add="true"
            :extra-params="{
              provider_id: id,
              cluster_company_id: 1,
            }"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <ActionLogList ref="actionLogList" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { providerBankWorkflowCollectionTypeOptions } from "@/core/data/options";
import ClusterService from "@/core/services/cluster.service";
import { hasForm, hasPermissions } from "@/view/mixins";
import NoteList from "@/view/content/Notes/NoteList";
import DocumentList from "@/view/content/documents/DocumentList";
import AutoAssessmentList from "./AutoAssessmentList";
import PaymentList from "./PaymentList";
import TransactionList from "@/view/content/transactions/TransactionList";

export default {
  name: "ClusterProviderForm",
  mixins: [hasForm, hasPermissions],
  components: {
    ActionLogList,
    AutoAssessmentList,
    DocumentList,
    NoteList,
    PaymentList,
    TransactionList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
    cluster: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.cluster) {
            this.formData.cluster_id = this.cluster.id;
            this.updateClusterId(this.cluster.id);
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      "bankIntegrationTypeList",
      "cluster",
      "clusterId",
      "fullExpenseTypeList",
      "providerTypeList",
    ]),
    childExpenseTypeList() {
      if (!this.formData.expense_type_id) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(
        this.formData.expense_type_id
      );
    },
    hasParent() {
      if (!this.id || !this.originalData || !this.originalData.id) {
        return false;
      }

      return this.originalData.parent_id !== null;
    },
    clusterHasCompany() {
      if (!this.id || !this.originalData) {
        return false;
      }

      if (!this.cluster || !this.cluster.active_company) {
        return false;
      }

      return true;
    },
    createParentEnabled() {
      if (!this.hasParent && this.clusterHasCompany) {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
      selectedTab: "info",
      status: {},
      selectedCompanyProviderId: null,
      dialog: null,
      formData: {
        cluster_id: null,
        provider_type_id: null,
        name: null,
        title: null,
        provider_code: null,
        expense_type_id: null,
        child_expense_type_id: null,
        tax_office: null,
        tax_number: null,
        auto_assessment: false,
        is_active: true,
        bank_workflow_type_id: 1,
        bank_integration_type_id: null,
        bank_keywords: [],
        city_id: null,
        district_id: null,
        bank_1_id: null,
        bank_1_iban: null,
        bank_2_id: null,
        bank_2_iban: null,
        phone: null,
        email: null,
        website: null,
        address: null,
        concern_name: null,
        concern_position: null,
        concern_email: null,
        concern_phone: null,
      },
      originalData: {},
      providerbankWorkflowTypeList: providerBankWorkflowCollectionTypeOptions,
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      this.$api
        .query(`cluster-providers/${this.id}/status`)
        .then((response) => {
          this.status = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });

      return this.$api
        .query(`cluster-providers/${this.id}`)
        .then((response) => {
          this.loadData(response);

          if (this.cluster.id !== response.data.data.cluster_id) {
            this.updateClusterId(response.data.data.cluster_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (formData.concern_phone) {
        formData.concern_phone = this.formData.concern_phone.replace(
          /[^0-9+]/g,
          ""
        );
      }

      if (formData.bank_1_iban) {
        formData.bank_1_iban = this.formData.bank_1_iban.replace(
          /[^0-9TR+]/g,
          ""
        );
      }

      if (formData.bank_2_iban) {
        formData.bank_2_iban = this.formData.bank_2_iban.replace(
          /[^0-9TR+]/g,
          ""
        );
      }

      this.isLoading = true;

      if (this.id) {
        this.$api
          .put(`cluster-providers/${this.id}`, formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            ClusterService.loadLawyerList();
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        formData.cluster_id = this.clusterId;

        this.$api
          .post(`cluster-providers`, formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            ClusterService.loadLawyerList();
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.updateClusterId(response.data.data.cluster_id);

            this.$router.replace({
              name: "definitions.cluster-providers.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleDisrespectParentClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`cluster-providers/${this.id}/disrespect-parent`)
        .then(() => {
          this.$toast.success(this.$t("saved"));

          setTimeout(() => {
            this.load();
          }, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleRespectParentClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      const params = { parent_id: this.selectedCompanyProviderId };

      this.$api
        .post(`cluster-providers/${this.id}/respect-parent`, params)
        .then(() => {
          this.$toast.success(this.$t("saved"));

          setTimeout(() => {
            this.load();
          }, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCreateParentClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`cluster-providers/${this.id}/create-parent`)
        .then((response) => {
          this.$toast.success(this.$t("saved"));

          setTimeout(() => {
            this.$router.push({
              name: "my-company.providers.edit",
              params: { id: response.data.data.id },
            });
          }, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.$router.back();
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Provider", this.id);
    },
  },
  mounted() {
    if (this.id) {
      this.load();
    }
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
